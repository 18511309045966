import React from 'react';
import bando2024 from '../assets/images/banners/banner.png';
import { Button } from 'react-bootstrap';

const BottomBanner = () => {
    return (
        <div className='d-flex justify-content-center py-4 pb-5 myBgBlue'>
            <div className='myMaxWidth1000 position-relative'>
                <img className='w-100 ' src={bando2024} alt="" />
                <Button className="position-absolute text-dark myCursor px-4 py-0 rounded-5 end-0 bottom-0 me-2" variant="primary" href='https://landings.comunicadhoc.it/voucher_internazionalizzazione' target='_blank' ><h6 className='m-0 text-light '>info</h6></Button>
            </div>
        </div>
    )
}

export default BottomBanner