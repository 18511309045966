import React from 'react';
import diagFucsia from '../assets/graphics/diagonal_fucsia.png';
import diagDarkgray from '../assets/graphics/diagonal_darkgray.png';
import magazineCirculation from '../assets/images/magazineCirculation.png';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import diagAcqua from '../assets/graphics/diagonal_acqua_reverse.png';
import { useSelector } from 'react-redux'

const Home_AboutUs = () => {

    const isIta = useSelector((state) => state.createUser.languageIta);

    return (
        <div className='text-light' >

            <div className='myBgTransparent50' style={{ backgroundImage: `url(${diagAcqua})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}></div>

            <div className='spacerLg bg-info'>{/* adaptive spacer */}</div>
            {/* part 1 */}
            <div className='myBgAcqua d-flex justify-content-center py-5'>

                <div className='p-2 px-3' style={{ maxWidth: "900px" }}>
                    {
                        isIta ?
                            <h1><b>Infodent</b> è una casa editrice e agenzia di marketing specializzata nel <b>B2B e B2C</b>, operante a livello globale nel settore dentale e medicale dal 1994. </h1>
                            : <h1><b>Infodent</b> is a <b>B2B & B2C</b> Marketing and Publishing House operating globally in the Dental and Medical field since 1994. </h1>
                    }
                    {
                        isIta ?
                            <h3 className='fw-light pt-5'><b>Infodent</b> fornisce servizi di marketing a 360 gradi per aiutare le <b>aziende produttrici a trovare distributori dentali e medicali in tutto il mondo</b>.</h3>
                            : <h3 className='fw-light pt-5'><b>Infodent</b> provides 360-degree marketing services to help <b>manufacturing companies find dental and medical dealers worldwide</b>.</h3>
                    }
                    <Link className='noDecoration' to={"/ourstrategy"}><div className='d-flex align-items-baseline text-primary myCursor' ><p className='fw-bold'>{isIta ? "La nostra Strategia" : "Our Strategy"} </p><i className="bi bi-caret-right-fill ms-2"></i></div></Link>
                </div>
            </div>

            {/* video youtube */}
            <div className='myBgAcqua pb-5'>
                <div className=' d-flex justify-content-center'>
                    <div className='ratio ratio-16x9 myMaxWidth1000'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/4p3BOsq-RpY?si=7C7mfgfjwmFkcg1j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className='myBgAcqua d-flex justify-content-center pb-5'>
                <div className='p-3 pt-5 text-center pb-5' style={{ maxWidth: "900px" }}>
                    <h1 className='display-4 fw-bold'>{isIta ? "AIUTIAMO L'INDUSTRIA A CONNETTERSI" : "WE HELP THE INDUSTRY CONNECT"}</h1>
                    {
                        isIta ?
                            <h3 className='fw-light pt-5'><b>La missione di Infodent</b> è creare servizi altamente specializzati e su misura per le esigenze del cliente. Una comunicazione multicanale pensata per incrementare il business delle imprese.  </h3>
                            : <h3 className='fw-light pt-5'><b>Infodent</b> mission is to create tailored-made and highly specialized services. A multichannel communication designed to increase your business.  </h3>
                    }
                </div>
            </div>

            {/* part 2 */}
            <div>
                <div className='myBgAcqua' style={{ backgroundImage: `url(${diagDarkgray})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}>
                    <div className='d-flex justify-content-center'>
                        <div className='p-2 px-3 text-center' style={{ maxWidth: "700px" }}>
                            <h1>{isIta ? "Le nostre riviste dentali e medicali sono distribuite in tutto il mondo" : "Our Dental & Medical Magazines are distributed Worldwide"}</h1>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center myBgDarkgray py-5'>
                    <img className='w-100' src={magazineCirculation} alt="" style={{ maxWidth: "1000px" }} />
                </div>
            </div>

            {/* part 3 */}
            <div>
                <div className='myBgDarkgray' style={{ backgroundImage: `url(${diagFucsia})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}>
                    <div className='d-flex justify-content-center'>
                        <div className='p-2 px-3' style={{ maxWidth: "700px" }}>
                            <h1 className='display-4 fw-bold'>{isIta ? "Non è abbastanza?" : "Not enough?"}</h1>
                            <h2 className='fw-light myTextLightgray text-center'>{isIta ? "Ecco cosa facciamo:" : "Here's all we do:"}</h2>
                        </div>
                    </div>
                </div>
                <div className='myBgFucsia p-3 d-flex justify-content-center'>
                    <div style={{ maxWidth: "700px" }}>
                        <div className='mb-5 mt-5'>
                            <div className='d-flex align-items-center gap-3'>
                                <i className="myFontSize42 bi bi-arrow-through-heart-fill" ></i>
                                {
                                    isIta ?
                                        <h4><b>Infodent</b> aiuta i produttori a entrare direttamente negli studi dentistici e nei laboratori</h4>
                                        : <h4><b>Infodent</b> helps manufacturers to get directly into Dental Offices and Laboratories</h4>
                                }
                            </div>
                            {
                                isIta ?
                                    <p className='text-dark myFontSize22'><i>Attraverso il nostro principale canale di comunicazione <b>Italiano B2C</b>, i nostri clienti possono raggiungere direttamente il cliente finale con ottimi risultati. </i></p>
                                    : <p className='text-dark myFontSize22'><i>Through our main <b>Italian B2C channel</b>, our clients can reach the end customer directly, with great results. </i></p>
                            }
                        </div>
                        <div className='text-end mb-5'>
                            <div className='d-flex align-items-center justify-content-end gap-3'>
                                {
                                    isIta ?
                                        <h4><b>Infodent</b> aiuta aziende ed associazioni dentali ad organizzare e gestire i loro eventi.</h4>
                                        : <h4><b>Infodent</b> helps Dental Companies & Associations to organize and manage their events.</h4>
                                }
                                <i className="myFontSize42 bi bi-flag-fill" ></i>
                            </div>
                            {
                                isIta ?
                                    <p className="text-dark myFontSize22"><i>Infodent fornisce <b>supporto, tecnologia e capacità organizzative</b> per soddisfare le vostre esigenze e realizzare la vostra visione.  </i></p>
                                    : <p className="text-dark myFontSize22"><i>Infodent provides <b>support, technology, and organizational skills</b> to meet your needs and make your vision come true.  </i></p>
                            }
                        </div>
                        <div className='mb-5'>
                            <div className='d-flex align-items-center gap-3'>
                                <i className="myFontSize42 bi bi-airplane-fill" ></i>
                                {
                                    isIta ?
                                        <h4><b>Infodent</b> aiuta i clienti a partecipare alle principali fiere di settore, senza spendere una fortuna.</h4>
                                        : <h4><b>Infodent</b> helps customers to take part at main trade shows, without paying a fortune.</h4>
                                }
                            </div>
                            {
                                isIta ?
                                    <p className="text-dark myFontSize22"><i>Con i nostri servizi di <b>co-esposizione</b> vi garantiamo uno stand sotto i riflettori a un prezzo imbattibile. Sia in campo medicale che dentale. </i></p>
                                    : <p className="text-dark myFontSize22"><i>With our <b>co-Exhibition services</b> you are guaranteed a booth in the spotlight at an unbeatable price. Both in Medical & Dental fields. </i></p>
                            }
                        </div>
                        <div className='text-end mb-5'>
                            <div className='d-flex align-items-center justify-content-end gap-3'>
                                {
                                    isIta ?
                                        <h4><b>Infodent</b> aiuta le aziende a creare la strategia di marketing più adatta alle loro esigenze.</h4>
                                        : <h4><b>Infodent</b> helps companies to create their own Marketing Strategy, fitting their needs.</h4>
                                }
                                <i className="myFontSize42 bi bi-wrench-adjustable" ></i>
                            </div>
                            {
                                isIta ?
                                    <p className="text-dark myFontSize22"><i>Con <b>avanzati strumenti digitali, giornalisti professionisti e 30 anni di esperienza</b>, siamo pronti a incrementare il vostro business. </i></p>
                                    : <p className="text-dark myFontSize22"><i>With advanced <b>Digital tools, Professional Journalists and 30 years of expertise</b>, we are ready to boost your business. </i></p>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default Home_AboutUs