import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _OurServices from '../components/_OurServices';

const OurServicesPage = () => {
    return (
        <>
            <MainLayout>
                <_OurServices />
            </MainLayout>
        </>
    )
}

export default OurServicesPage