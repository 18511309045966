import React from 'react';
import _Home from "../components/_Home"
import MainLayout from '../layouts/MainLayout';

const HomePage = () => {
    return (
        <>
            <MainLayout>
                <_Home />
            </MainLayout>
        </>
    )
}

export default HomePage