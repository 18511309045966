import React from 'react';
import MainLayout from '../layouts/MainLayout';

const CookiesPage = () => {
  return (
    <MainLayout>
      <div className='p-5 bg-light text-secondary'>

      
        <h2><b>Cookies and Privacy Policy</b></h2>
        <hr />
        <h3>The protection of Privacy is important to us</h3>
        <p>Thank you for visiting our website. We are very careful about the protection of your Personal data and we care not only to provide you a wide online service but also to guarantee your right to an informed self-determination; Please note that the Policy is given only for the <b>Infodent International</b> website and not also for other websites consulted secondarily by the user from our links.</p>
        <h3>WHO IS THE PROPRIETOR OF THE TREATMENT AND THE TREATMENT CONTROLLER?</h3>
        <p>As a consequence of the use of the  Website, personal information concerning identified or identifiable people might be collected and processed. The proprietor of their treatment is Infodent srl, with registered office in  Via dell'Industra 65 - 01100 Viterbo (VT) Italy, which can, in turn, designates treatment’s appointees, including external parties.</p>
        <h3>WHAT ARE YOUR PERSONAL DATA WE COLLECT?</h3>
        <p>
          <b>a Browsing data</b><br />
          Information systems and software procedures used to operate the website acquire, during the normal course of operation, some personal data whose transmission is implicit in the communication protocols of the Internet.<br />
          Each time a user enters to this website and each time he refers to a content we save your access data, and possibly also from the treatment controller and the treatment’s appointees, in the form of protocol files.<br />
          Every protocol file is composed by:
        </p><ul>
          <li>The website from which our page has been drawn;</li>
          <li>His IP address;</li>
          <li>Date and time of access;</li>
          <li>The client’s request;</li>
          <li>The http response code;</li>
          <li>The amount of transmitted data;</li>
          <li>The browser and OS used.</li>
        </ul>
        <p>	<b>b Data supplied voluntarily by the user</b><br />
          From several parts of this websites you have the possibility to transmit us personal data (ex. E-mail address, name, postcode, contingent data contained in the requests). These data transmission is carried-out on a discretionary, explicit and voluntarily basis, and entails the subsequential acquisition of the sender’s address, necessary in order to reply to the requests, and also any other personal data contained in the message.
        </p><p><b>c Cookies</b><br />
          In order to make your visit to our website as comfortable as possible, we use cookies for the presentation of our products range. They are small text files that are saved locally in the cache of your Browser, therefore in your computer to improve the experience of the website’s use.
        </p>
        <h3>OUR COOKIES HELP US:</h3>
        <p>
        </p><ul>
          <li>To make our website as readable as we would expect</li>
          <li>To avoid to login each time the website is visited</li>
          <li>To remember your settings during and in between visits</li>
          <li>To improve the website’s speed / safety</li>
          <li>To allow sharing pages on social Networks such as Facebook</li>
          <li>To continuously improve our Website</li>
          <li>To make our marketing more efficient</li>
        </ul>
        <p></p>
        <h3>OUR COOKIES ARE NOT USED:</h3>
        <ul>
          <li>To gather all the personally identifiable information (without your expressed consent)</li>
          <li>To pass data to advertising networks</li>
          <li>To pass personally identifiable data to third parties</li>
          <li>To pay sell commissions</li>
        </ul>
        <p>if you wish to disable the use of cookies you need to personalize the settings on your own computer by setting the erasure of all cookies or by activating a warning message when cookies are saved. In order to proceed without modifying the cookies’ application is sufficient to continue the browsing.
        </p>
        <h3>ADDITIONAL INFORMATION ON OUR COOKIES</h3>
        <p>
          In the following table you can see the cookies we use and the reasons why we need cookies. Some of these cookies are necessary in order to make available this website or to allow a more effective and safer exploration, and they do not permit the acquisition of identifying information regarding the user;  these information have already been saved on the user’s hard drive, without any specific approval required. Other cookies instead, even though they are not used to recognize the user and evaluate his behaviour, are saved only after obtaining his approval.
        </p>
        <h3>FUNCTIONAL COOKIES</h3>
        <p>
        </p><ul>
          <li>Define if a user is authenticated or not</li>
          <li>Remember searching settings</li>
          <li>Remember if terms and conditions have been accepted</li>
          <li>Remember if you already answered questions (ex. questionnaire)</li>
        </ul>
        <p></p>
        <h3>THIRD PARTIES COOKIES</h3>
        <p>
          Our website, as most of websites, includes functionalities given by third parties. Third parties cookies originate from integration of elements by other websites. A common example is an incorporated Youtube video. Our website includes the following cookie users’ services:
        </p><ul>
          <li><b>Youtube</b>: for additional information on Youtube cookies visit: <a href="http://www.google.com/intl/it_it/policies/technologies/cookies/" target="_blank">Google cookie</a></li>
          <li><b>Social Network</b>: the privacy implications on this differ for each social network and depend on the privacy settings you chose on this networks.
            The current website might contain links to the most common social networks, for additional information refer to the cookies and privacy policy of each social network.
            <a href="https://it-it.facebook.com/about/privacy" target="_blank">Facebook</a>, <a href="http://www.google.com/intl/it_it/policies/technologies/cookies/" target="_blank">Google+</a>, <a href="https://help.instagram.com/155833707900388" target="_blank">Instagram</a>, <a href="https://twitter.com/privacy?lang=it" target="_blank">Twitter</a>,
            <a href="https://www.linkedin.com/legal/cookie_policy" target="_blank">LinkedIn</a>, <a href="https://about.pinterest.com/it/privacy-policy" target="_blank">Pinterest</a>.</li>
          <li><b>Google Analytics</b>: this website uses Google Analytics, a web analysis service provided by Google.
            Google analytics uses cookies to allow the website to analyse how the users operate on the website.
            For additional information on Google Analytics cookies visit: <a href="http://www.google.com/intl/it_it/policies/technologies/cookies/" target="_blank">Google cookie</a></li>
          <li><b>Web Analysis with PIWIK</b>: this website uses the “PIWIK” open source software for statistical analysis on the number of user’s visits.
            For additional information on PIWIK cookies visit: <a href="http://piwik.org/faq/general/faq_146/" target="_blank">Piwik FAQ</a></li>
          <li><b>Google Maps</b>: Google incorporate several cookies in every page that includes Google Maps.
            Even though we do not have any control on Google’ set cookies, they seem to enlist a mix of information to calculate the number and the behaviour of Google Maps users.
            <a href="http://www.google.com/intl/it_it/policies/technologies/cookies/" target="_blank">Additional informations</a></li>
          <li><b>Facebook</b>: It is also installed Facebook Pixel cookie which allows Infodent srl to monitor conversions happening on its website as a result of the ads it is running on Facebook.
            <a href="https://www.facebook.com/help/cookies/" target="_blank">Additional informations</a></li>
        </ul>
        <h3>HOW TO REQUEST CONSENT FOR THE USE OF COOKIES</h3>
        <p>
          Closing this banner, browsing this page or clicking any element that will allow the use of cookies.
        </p>
        <h3>HOW TO EXPRESS PREFERENCE REGARDING COOKIES</h3>
        <p>It is possible to express your choice regarding our website usage of cookies also through your browser’s settings, as follow:</p>
        <p>
          <b>Microsoft Internet Explorer</b>
        </p><ol>
          <li>Click on “Tools” at the top of your browser window and select “Internet Options” and then click on the “Privacy” tab.</li>
          <li>To activate cookies, the Privacy level has to be set on “Medium” or low;</li>
          <li>Setting the Privacy level on “Medium” will deactivate the use of cookies.</li>
        </ol>
        <p></p>
        <p><b>Mozilla Firefox</b>
        </p><ol>
          <li>Click on “Tools” at the top of your browser window and select “Options”;</li>
          <li>Select the “Privacy” tab;</li>
          <li>Click on “Cookies”, then choose the selection on “Accept website cookies” and “Accept third parties cookies”;</li>
        </ol>
        <p></p>
        <p><b>Google Chrome</b>
        </p><ol>
          <li>Click on “Tools” icon</li>
          <li>Select “Settings” and then “Advanced Settings”</li>
          <li>Select “Content Settings” under the invoice “Privacy”</li>
          <li>In the “Cookies” tab it is possible deselect cookies and save preferences.</li>
        </ol>
        <p></p>
        <p><b>Safari</b>
        </p><ol>
          <li>Click on the “Cog” tab at the top of your browser window and select the “Preferences” option;</li>
          <li>Click on “Protection” and choose the selection for “Block third parties and cookies for advertisement”;</li>
          <li>Click on “Save”.</li>
        </ol>
        <p></p>
        <h3>WHAT DO WE DO WITH YOUR PERSONAL DATA?</h3>
        <p>We only use your data for the purpose for which they were collected. To process your requests and the usage of our services we sometimes designate suppliers (including external operatives or external treatment’s responsible), which receive and use only the necessary data to carry out their assignment.</p>
        <p>a The protocol files and their contents are managed from us to control the website proper functioning and protect our systems, for example identifying potential attacks to our systems. The data within the protocol files, or the ones they represent, do not allow us to associate such data to one specific person. In any way, we provide correspondence from other sources.</p>
        <p>b Personal data voluntarily given are treated in order to:</p>
        <ul>
          <li>Send you our Newsletter, if you subscribed;</li>
          <li>Process your requests.</li>
        </ul>
        <p>If you express consensus in receiving our newsletter, your e-mail address will be used to send you a newsletter in form of a mail with products information, promotions and latest news on our offers.</p>
        <p>c Regarding the purpose of cookies’ processing, we refer  to the above mentioned table.</p>
        <p>As soon as your personal data would not be necessary for the purposes for which they were gathered, we will promptly erase the data, unless the law requires filing obligations.</p>
        <p></p>
        <h3>HOWYOUR PERSONAL DATA ARE PROCESSED</h3>
        <p>
          Your personal data are processed with automated (or manual) systems from  the Holder and the people in charge of the treatment specifically appointed, even third parties, for no longer than necessary for the purposes for which the data were originally collected; adequate safety measures are adopted to prevent data loss, illegal or incorrect data use or unauthorised access.
        </p>
        <h3>IS THE SUPPLY OF YOUR PERSONAL DATA COMPULSORY?</h3>
        <p>
          A part from what has been specified with respect to navigation data (protocol files), and in part respect to cookies (that can eventually be blocked) users are free to provide the personal data contained in the request forms or anyhow indicated in the “contacts” section to request the dispatching of the Newsletter, informative material or other communications. Failure to provide them may render the provision of the services requested impossible.
        </p><h3>WHAT ARE YOUR RIGHTS?</h3>
        <p>
          According to article 7 of Legislative decree 196/03, you have the right to obtain confirmation about the existence of your data and to know the content and the origin, to verify its accuracy or request data integration, update or correction;  you also have the right to obtain the cancellation, transformation into anonymous form or the blocking of data being processed in violation of the law, and also to object in any case, for lawful reasons, to their processing. To exercise these rights, please write or e-mail us to the contacts listed below. If you expressed the approval for receiving the newsletter you can withdraw in any moment such approval writing or e-mailing us to the contacts listed below.
        </p>
        <h3>FURTHER QUESTIONS?</h3>
        <p>
          For any further enquiry about the collection, the processing and use of your personal data, please contact:<br />
          <b>Infodent srl</b><br />
          Via Dell'industria, 65<br />
          01100 Viterbo - Italy<br />
          T +39 0761 352198<br />
          F +39 0761 352133<br />
          e-mail: <a href="mailto:privacy@infodent.com">privacy@infodent.com</a>

        </p>

      
      </div>

    </MainLayout>)
}

export default CookiesPage