import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import videoMaker from '../../assets/images/productVideo/video-making.jpg';
import mrkCampaign from '../../assets/images/productVideo/marketing.png';
import camera from '../../assets/images/productVideo/camera.png';
import social from '../../assets/images/productVideo/social-media.png';
import responsive from '../../assets/images/productVideo/responsive-design.png';
import editingTeam from '../../assets/images/videoInterview/teamEditing.jpg';
import ita from '../../assets/images/productVideo/italy.png';
import eng from '../../assets/images/productVideo/united-kingdom.png';
import youtube from '../../assets/images/videoInterview/youtube.png';
import { useSelector } from 'react-redux';

const ProfessionalVideoPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${videoMaker})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{
              isIta ?
                <div>Video prodotto professionale</div>
                : <div>Professional Product Video</div>
            }
            </h1>
            {
              isIta ?
                <div><h2 className='font-nycd text-info my-5'>LASCIA CHE IL TUO PRODOTTO SIA LA STAR</h2></div>
                : <div><h2 className='font-nycd text-info my-5'>LET YOUR PRODUCT BE THE STAR</h2></div>
            }

            <h3 className='fw-light'>{
              isIta ?
                <div>Nell'era digitale, i video dinamici, girati in modo professionale, sono i migliori ambasciatori di un brand o di un prodotto di successo.</div>
                : <div>In the digital era, professionally shot and visually dynamic videos are the best brand ambassadors you could possibly hope for.</div>
            }
            </h3>
          </div>
        </div>

        <div className='bg-dark'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex flex-wrap justify-content-center gap-1 mb-5'>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/TgmRmSUg8fo?si=xyNRTTqPUrU8yQ8J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/NfJJ9x-mVZA?si=OarSfvQaTXZx98_1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/YzmBqGK4BTc?si=CRCReHJKYzYgCCSc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/WD3E2TSDFOI?si=av1W0kyDBAb2Ej8_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/enTywv8afa0?si=BbCbrF-CIfiphPT2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/GgCG6da51m8?si=lSVJiDHrlJwim3R1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='my-5 py-5 d-flex justify-content-center '>
            <a className='noDecoration d-flex justify-content-center align-items-center gap-3 px-3' href="https://www.youtube.com/@InfodentSrl/videos" target='_blank'>
              <img className='myMaxWidth60' src={youtube} alt="" />
              <h2 className='m-0'>{
                isIta ?
                  <div>Guarda tutti i nostri video prodotti</div>
                  : <div>Watch all our Product Video</div>
              }
              </h2>
            </a>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='text-secondary myMaxWidth700'>{
              isIta ?
                <div>Rispetto ad altri media digitali, i video hanno dimostrato di essere il <b>modo più efficace per promuovere prodotti e servizi</b>. Semplici e facilmente recepibili, i video catturano l'attenzione dello spettatore e trasmettono in modo efficiente il tuo messaggio, creando una connessione duratura che assicura che i tuoi <b>prodotti vengano ricordati e riconosciuti</b>.</div>
                : <div>Compared to other digital media, videos have been proven to be the <b>most effective way to promote products and services</b>. Straight-forward and easily receivable, videos grab the viewer’s attention and efficiently get your message across, creating a lasting connection that ensures that your <b>products are remembered and recognized</b>.</div>
            }
            </h2>
            <img className='myMaxWidth200' src={mrkCampaign} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={camera} alt="" />
            <h2 className='myMaxWidth700'>{
              isIta ?
                <div>I video professionali dei prodotti possono essere girati durante le principali fiere di settore, durante gli eventi aziendali oppure il nostro team può organizzare una giornata di riprese presso la vostra sede centrale. I video dei prodotti vengono registrati utilizzando attrezzature di alta qualità che garantiscono i migliori risultati, grazie anche ad una post-produzione di primo livello.</div>
                : <div>Professional product videos can be shot during the main <b>trade fairs</b>, at <b>company events</b>, or our team can arrange a day of shootings at your <b>headquarters</b>. Product videos are recorded using top quality equipment that guarantees the greatest results, and post-production is taken care of right afterwards.</div>
            }
            </h2>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center gap-5'>
            <img className='myMaxWidth80' src={social} alt="img" />
            <img className='myMaxWidth80' src={responsive} alt="img" />
          </div>
          <h2 className='text-secondary text-center myMaxWidth1200'>{
            isIta ?
              <div>In base alle tue preferenze, i video finali possono essere caricati su <b>YouTube</b> o condivisi sui <b>social media</b>: tutti i video saranno girati nei <b>formati corrispondenti e più adatti</b> per garantire ottimi risultati e usabilità.</div>
              : <div>According to your preferences, the final videos can be uploaded onto <b>YouTube</b> or shared on <b>social media platforms</b>: all videos will be shot in the corresponding and most <b>fitting formats</b> to ensure great results and usability.</div>
          }
          </h2>
          {
            isIta ?
              <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Rendi il tuo prodotto indimenticabile!</h1></div>
              : <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Make your product unforgettable!</h1></div>
          }

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${editingTeam})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h2 className='fw-light my-5 myMaxWidth1200'>{
              isIta ?
                <div>Tutto il resto, dal montaggio ai ritocchi finali della post-produzione, sarà curato dal nostro team per consegnarti il ​​prodotto finito nel più breve tempo possibile.
                  Aumenta le tue possibilità di essere visto e ascoltato: scegli i video per far conoscere la tua storia.</div>
                : <div>Everything else, from editing to the final touches of post-production, will be taken care by our team to meet you with the finished product in the shortest possible time.
                  Enhance your chances to be seen and heard: choose videos to get your story out there.</div>
            }

            </h2>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth50' src={ita} alt="img" />
              <img className='myMaxWidth50' src={eng} alt="img" />
            </div>
            <h2 className='fw-light mb-5 myMaxWidth1200'>{
              isIta ?
                <div>Disponibile in lingua inglese e italiana.</div>
                : <div>Available in english and italian language.</div>
            }
            </h2>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default ProfessionalVideoPage