import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import bgTrade from '../../assets/images/coExhibition/bgTradeShow.jpg';
import place from '../../assets/images/leaflet/check.png';
import moneySaving from '../../assets/images/leaflet/deposit.png';
import leaflet from '../../assets/images/leaflet/leaflet.png';
import spreading from '../../assets/images/leaflet/spreading.png';
import { useSelector } from 'react-redux';

const LeafletPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgTrade})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{
              isIta ?
                <div>Distribuzione di Brochure</div>
                : <div>Leaflet Distribution</div>
            }
            </h1>
            {
              isIta ?
                <div><h2 className='font-nycd text-info my-5'>LASCIA CHE IL TUO BRAND VIAGGI PER IL MONDO</h2></div>
                : <div><h2 className='font-nycd text-info my-5'>LET YOUR BRAND TRAVEL THE WORLD</h2></div>
            }

            <h3 className='fw-light'>{
              isIta ?
                <div>Non devi partecipare a tutti i grandi eventi là fuori: lo faremo noi per te.</div>
                : <div>You don’t need to attend all the big events out there: we’ll do it for you.</div>
            }
            </h3>
          </div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth80' src={leaflet} alt="img" />
          </div>
          <h2 className='text-center myMaxWidth1200'>{
            isIta
              ? <div>Nel nostro settore succedono sempre tante cose. Quando non puoi essere fisicamente presente, il tuo brand può venire con noi. La distribuzione di brochure è un modo efficiente che ti farà risparmiare tempo e denaro e ti garantirà di non perdere mai l'occasione di incontrare il tuo prossimo cliente.</div>
              : <div>There is always so much going on in our industry. When you can’t physically be there, your brand can come along with us. Leaflet distribution is an efficient and time-and-money-saving way to make sure you never miss the chance of meeting your next client.</div>
          }
          </h2>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth80' src={moneySaving} alt="img" />
          </div>
          {
            isIta
              ? <div><h1 className='font-nycd my-5 text-center fw-light text-info'>Risparmia tempo e denaro con il nostro servizio di distribuzione brochure! </h1></div>
              : <div><h1 className='font-nycd my-5 text-center fw-light text-info'>Save time and money with our Leaflet Distribution service! </h1></div>
          }

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={spreading} alt="" />
            <div>
              <h2 className='myMaxWidth700'>{
                isIta
                  ? <div>Le vostre brochure saranno esposti presso il nostro stand - sempre ben posizionato in tutte le principali fiere del mondo - e  sarà il nostro personale ad occuparsi della distribuzione, raccogliendo info e biglietti da visita al posto vostro. Alla fine dell'evento i biglietti da visita raccolti verranno inviati direttamente ai vostri uffici.</div>
                  : <div>Your flyers will be on display at our booth – which are always <b>well-located</b> at all major trade fairs worldwide – and <b>our staff will distribute</b> them to potential and interested clients, while also taking care of <b>collecting their business cards</b> and information and sending them right to your offices afterwards.</div>
              }
              </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <div>
              <h2 className='myMaxWidth700 text-secondary'>{
                isIta
                  ? <div>Proprio come con i nostri servizi di vetrine, la distribuzione di brochure consente al tuo brand e ai tuoi prodotti di essere presente <b>nel luogo in cui il settore si incontra e si connette</b>: l'occasione perfetta per testare il nuovi territori o rafforzare la tua presenza in mercati già conosciuti.</div>
                  : <div>Just as with our real showcase services, leaflet distribution allows your label and products to <b>be where the industry meets and connects</b>: the perfect occasion for testing the waters in new areas or reinforcing your presence in already-known markets.</div>
              }
              </h2>
            </div>
            <img className='myMaxWidth150' src={place} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


      </div>
    </MainLayout>
  )
}

export default LeafletPage