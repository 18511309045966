import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _Login from '../components/_Login';

const LoginPage = () => {
  return (
    <>
    <MainLayout>
        <_Login/>
    </MainLayout>
    </>
  )
}

export default LoginPage