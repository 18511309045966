import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _OurDatabase from '../components/_OurDatabase';

const OurDatabasePage = () => {
    return (
        <>
            <MainLayout>
                <_OurDatabase />
            </MainLayout>
        </>
    )
}

export default OurDatabasePage