import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _OurStrategy from '../components/_OurStrategy';

const OurStrategyPage = () => {
    return (
        <>
            <MainLayout>
                <_OurStrategy />
            </MainLayout>
        </>
    )
}

export default OurStrategyPage