import React, { useEffect, useState } from 'react';
import Myvideo from '../assets/video/vidHome.mp4';
import Home_AboutUs from './Home_AboutUs';
import Home_OurServices from './Home_OurServices';
import diagBlack from '../assets/graphics/diagonal_black.png';
import infodent from '../assets/images/paperMagazine/infodent.png';
import infomedix from '../assets/images/paperMagazine/infomedix.png';
import tabloid from '../assets/images/paperMagazine/tabloid.png';
import { useSelector } from 'react-redux';
import PopUp from './PopUp';
import { Button } from 'react-bootstrap';
import BottomBanner from './BottomBanner';



const _Home = () => {

    const popupDate = localStorage.getItem("pop-date");
    const isIta = useSelector((state) => state.createUser.languageIta);
    const [closePopup, setClosePopup] = useState(popupDate ? true : false);

    const closePopupFunc = () => {
        localStorage.setItem("pop-date", Date.now())
        setClosePopup(true)
    }

    useEffect(() => {

        if (popupDate) {
            if (Date.now() - +popupDate > (1000)) {
                localStorage.removeItem("pop-date");
                setClosePopup(false);
                setTimeout(() => {
                    setClosePopup(true)
                }, 20000)
            } else {
                setClosePopup(true);
            }
        } else {
            setTimeout(() => {
                setClosePopup(true)
            }, 10000)
        }

    }, [])

    return (
        <div style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

            {
               true || closePopup ?
                    null
                    :
                    <div className='position-relative d-flex justify-content-center'>
                        <div className='position-absolute p-3 pt-5 d-flex justify-content-center' >
                            <i className="bi bi-x-lg position-absolute end-0 me-4 mt-2 text-light display-6 myCursor rounded-5" onClick={closePopupFunc} style={{ zIndex: "1" }}></i>
                            <PopUp />
                            <Button className="position-absolute text-dark myCursor px-4 rounded-5 popupCallButton border" variant="primary" href='https://landings.comunicadhoc.it/voucher_internazionalizzazione' target='_blank' onClick={closePopupFunc} ><h4 className='m-0 text-light'>info</h4></Button>
                        </div>
                    </div>

            }

            <div>
                <div className='myBgDark' style={{ height: "calc(100vh - 59px)", overflow: "hidden", zIndex: "7" }}>
                    <video src={Myvideo} autoPlay muted style={{ width: "100vw", height: "100%", objectFit: "cover" }} />
                </div>
            </div>


            <div className='myBgTransparent50' style={{ backgroundImage: `url(${diagBlack})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}></div>

            {/* part 0 */}
            <div className='text-light pb-5 myBgTransparent50'>
                <div className='spacerLg'>{/* adaptive spacer */}</div>
                <h1 className='fw-bold display-4 m-0 mb-4 px-3 pt-5 text-center'>{isIta ? "Visita le nostre riviste online" : "Visit our Magazines Websites"}</h1>
                <div className='d-flex flex-wrap justify-content-center pt-4 gap-2'>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infomedixinternational.com/">
                        <img className="myMaxWidth90" src={infomedix} alt="" />
                        {isIta ? <p>Medicale <br /> Internazionale</p> : <p>Medical <br /> International</p>}
                    </a>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infodentinternational.com/">
                        <img className="myMaxWidth90" src={infodent} alt="" />
                        {isIta ? <p>Dentale <br /> Internazionale</p> : <p>Dental <br /> International</p>}
                    </a>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infomedixodontoiatria.it/">
                        <img className="myMaxWidth90" src={tabloid} alt="" />
                        {isIta ? <p>Dentale <br /> Italia</p> : <p>Dental <br /> Italy</p>}
                    </a>
                </div>
                <div className='spacerLg'>{/* adaptive spacer */}</div>
            </div>

            <Home_AboutUs />
            <Home_OurServices />
            <BottomBanner />
        </div>
    )
}

export default _Home