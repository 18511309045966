import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import newsletter from '../../assets/images/dem/newsletter.jpg';
import dem from '../../assets/images/dem/email-marketing.png';
import database from '../../assets/images/dem/database.png';
import looking4 from '../../assets/images/dem/Looking4Dealer.png';
import investment from '../../assets/images/dem/investment.png';
import target from '../../assets/images/dem/target.png';
import editingTeam from '../../assets/images/videoInterview/teamEditing.jpg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DemPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${newsletter})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{ 
    isIta 
    ?<div>Marketing via e-mail diretto</div>
    :<div>Direct Email Marketing</div>
  }
</h1>
{ 
    isIta 
    ?<h2 className='font-nycd text-info my-5'>IL TUO PROSSIMO CLIENTE È A UN SOLO PASSO DI DISTANZA</h2>
    :<h2 className='font-nycd text-info my-5'>YOUR NEXT CLIENT IS ONLY ONE STEP AWAY</h2>
  }

            <h3 className='fw-light'>{ 
    isIta 
    ?<div>È più di una semplice e-mail: il nostro servizio di e-newsletter è la porta di accesso al tuo prossimo cliente.</div>
    :<div>It’s more than just an email: our e-newsletter service is the gateway to your next client.</div>
  }
 </h3>
            <div className=' pt-5 d-flex justify-content-center'>
              <img className='myMaxWidth80' src={dem} alt="img" />
            </div>
          </div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>

          <div className='py-5 pt-5 d-flex justify-content-center'>
            <img className='myMaxWidth300' src={looking4} alt="img" />
          </div>
          <h2 className='text-secondary text-center myMaxWidth1200'>{ 
    isIta 
    ?<div>Che tu stia cercando di trovare nuovi distributori per i tuoi prodotti e potenziali clienti, o un modo efficiente per promuovere eventi ad un vasto pubblico, le newsletter possono aiutarti efficacemente a raggiungere il tuo obiettivo e a massimizzare il tuo investimento.</div>
    :<div>Whether you are <b>looking to find new distributors</b> for your products and prospective clients, or an efficient way to promote events to a vast audience, newsletters can effectively help you reach your goal and <b>maximize your investment</b>.</div>
  }
 </h2>
          <div className='py-5 pt-5 d-flex justify-content-center'>
            <img className='myMaxWidth200' src={investment} alt="img" />
          </div>
          { 
    isIta 
    ?<div></div>
    :<div></div>
  }
<h1 className='font-nycd my-5 text-center fw-light text-info'>Grow yor Business!</h1>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='myMaxWidth700'>{ 
    isIta 
    ?<div>Le nostre newsletter sono generalmente un prodotto one-shot che trasmetterà il tuo messaggio alle persone giuste e <b>le convertirà in clienti</b>. La loro potenza e il loro successo non risiedono solo nelle loro qualità intrinseche, ma anche nel fatto che possono essere <b>trasmesse al pubblico desiderato</b>.</div>
    :<div>Our newsletters are generally a one-shot product that will deliver your message to the right people and <b>convert it into customers</b>. Their power and success stand not only in their inherent qualities, but also in the fact that they can be <b>delivered to the desired audience.</b></div>
  }
 </h2>
            <img className='myMaxWidth200' src={target} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={database} alt="" />
            <div>
              <h2 className='text-secondary myMaxWidth700'>{ 
    isIta 
    ?<div>Il nostro database, che coltiviamo da oltre 30 anni, comprende oltre <b>70.000 contatti</b>, tra <b>distributori, produttori e professionisti del settore dentale e medicale</b>. Ogni newsletter è progettata su misura e mira a raggiungere esattamente il segmento che stai cercando.</div>
    :<div>Our database – which we have been cultivating for over 30 years – includes over <b>70K contacts</b>, among <b>distributors, manufacturers and professionals of the dental and medical field</b>. Each newsletter is designed and targeted to reach exactly the segment you are looking for.</div>
  }
</h2>
              <Link className='noDecoration' to={"/ourdatabase"}><div className='d-flex align-items-baseline text-primary myCursor' ><p className='fw-bold'>{isIta? "Statistiche del Database":"Database Statistics"} </p><i className="bi bi-caret-right-fill ms-2"></i></div></Link>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${editingTeam})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h2 className='fw-light my-5 myMaxWidth1200'>{ 
    isIta 
    ?<div>I nostri servizi di newsletter possono includere anche la creazione di una <b>landing page</b>: uno strumento potente in tutte quelle occasioni in cui hai bisogno di raccogliere informazioni o condividere contenuti extra con il tuo pubblico. Qualunque siano le tue esigenze, la realizzeremo abilmente attorno ai tuoi desideri <b>per aumentare conversioni e ritorni</b>.</div>
    :<div>Our newsletter services can also include the creation of a <b>landing page</b>: a powerful instrument in all those occasions when you need to collect information or share extra content with your audience. Whatever your needs are, we will skillfully craft it around your desires <b>to boost conversions and returns</b>.</div>
  }

            </h2>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default DemPage