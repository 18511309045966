import { React, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { createUserFunc, deleteUserFunc, getAllUserFunc } from '../states/createUserState';
import { useDispatch, useSelector } from 'react-redux';


const _Users = () => {
    const [email, setEmail] = useState("");
    const [pssw, setPssw] = useState("");
    const [message, setMessage] = useState("");

    const isLoading = useSelector((state) => state.createUser.isLoading);
    const allUsersData = useSelector((state) => state.createUser.allUsersData);

    const dispatch = useDispatch();

    const createUserSubmit = async () => {

        if (!email || !pssw) {

            setMessage("Fill all input fields!")
            return

        } else {

            dispatch(createUserFunc({ email: email, pssw: pssw }))
                .then((res) => {
                    setMessage(res.payload.message)
                    if (res.payload.statusCode === 200) {
                        setEmail("");
                        setPssw("");
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    setMessage(err)
                })
        }
    };

    const deleteUser = async (input) => {

        dispatch(deleteUserFunc(input))
            .then((res) => {
                if (res.payload.statusCode === 200) {
                    window.location.reload()
                }
            })
    }

    useEffect(() => {
        dispatch(getAllUserFunc());
    }, [])

    return (
        <div className='mt-3 text-light'>
            <hr />
            <h1 className='text-center fw-light'>Users</h1>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='bg-light p-4 rounded-5 d-flex flex-column justify-content-center align-items-center' style={{ width: "90vw", maxWidth: "600px" }}>
                    <h4 className='mb-4 text-center fw-light text-secondary'>Create New User</h4>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            email
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={(e) => { setEmail(e.target.value) }}
                            value={email}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            password
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type='password'
                            onChange={(e) => { setPssw(e.target.value) }}
                            value={pssw}
                        />
                    </InputGroup>
                    <Button className='mt-3 w-50' variant="success" size="sm" onClick={() => { createUserSubmit() }} >{isLoading ? <Spinner animation="border" size="sm" /> : "create"}</Button>
                    <p className='mt-2 text-dark'>{message}</p>
                </div>
            </div>

            <div className='d-flex flex-column align-items-center flex-wrap my-5'>
                {
                    allUsersData && allUsersData.map((el) => {
                        return (
                            <div className='px-4 py-3 my-2 rounded-5 bg-dark border'>
                                <div className='d-flex justify-content-center gap-5 align-items-center'>
                                    <h4 className='fw-light m-0'>{el.email.split("@")[0]}</h4>
                                    <i className="bi bi-trash-fill text-danger myCursor" onClick={() => { if (window.confirm("Are you sure?")) { deleteUser(el.id) } }}></i>
                                </div>
                                <p className='text-center text-secondary mb-0'>{el.email}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default _Users