import { React, useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useSelector, useDispatch } from 'react-redux';
import { getAllTeamFunc } from '../states/generalDataState';
import Spinner from 'react-bootstrap/Spinner';


const _AboutUs = () => {

  const allTeam = useSelector((state) => state.generalData.allTeam);
  const isLoadingTeam = useSelector((state) => state.generalData.isLoadingTeam);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllTeamFunc())
  }, [])


  return (
    <>

      {
        isLoadingTeam ?
          <div className=' d-flex justify-content-center' style={{ height: "calc(100vh - 59px)" }}>
            <div className='h-50 w-100 d-flex align-items-center justify-content-center'>
              <Spinner animation="border" variant="light" />
            </div>
          </div> :

          <div className='d-flex flex-wrap text-light justify-content-center py-5 px-3 ' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>
            {
              allTeam && allTeam.map((el) => {
                return (
                  <div className='authorCard myBgTransparent50 m-2 p-3 rounded-5' key={nanoid()}>
                    <div className='d-flex align-items-center justify-content-center'>

                      <div className='myMaxWidth150 m-5'>
                        <img className='img-fluid' src={require(`../assets/teams/${el.name.split(" ")[0] + el.surname.toString()}.png`)} alt="" />
                      </div>
                      
                    </div>
                    <div className='text-center mt-3'>
                      <h3 className='px-2'>{el.name} {el.surname}</h3>
                      <h4 className='text-info fw-light mt-3'><i>{el.working_position}</i></h4>
                    </div>
                  </div>
                )
              })
            }
            <div style={{ height: "100px" }}></div>
          </div>

      }

    </>

  )
}

export default _AboutUs