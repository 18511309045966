import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import animationBg from '../../assets/images/3Danimations/renderBg.jpg';
import graphic from '../../assets/images/3Danimations/graphic-tablet.png';
import complex from '../../assets/images/3Danimations/problem-solving.png';
import { useSelector } from 'react-redux';

const AnimationsPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${animationBg})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{
              isIta ?
                <div>Animazione 3D e 2D</div>
                : <div>3D & 2D Animation</div>
            }
            </h1>
            <h2 className='font-nycd text-info my-5'>{
              isIta ?
                <div>VAI OLTRE LE PAROLE</div>
                : <div>GO BEYOND WORDS</div>
            }
            </h2>
            <h3 className='fw-light'>{
              isIta ?
                <div>Ottimizza i tuoi sforzi di marketing comunicando in modo efficace l'unicità dei tuoi prodotti e lasciando un'impressione duratura nei tuoi clienti ideali.</div>
                : <div>Enhance your marketing efforts by effectively communicating the uniqueness of your products and leave a lasting impression on your ideal customers.</div>
            }
            </h3>
          </div>
        </div>

        <div className='bg-dark'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex flex-wrap justify-content-center gap-1'>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Vj5RT8Yx7-s?si=TltJZt6HZNcsO6IS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/q2flxu0ofac?si=MAt-fjoPq-0KsTKZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/_LXoHC-Nn2s?si=cO1spImGje4lUFXL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4p3BOsq-RpY?si=7C7mfgfjwmFkcg1j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/kuoAOVT7j_Q?si=YUWDrf4eeBqc3E0e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/n_SwwVa3GjE?si=q3qMQoYscmcmahaz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth200' src={complex} alt="img" />
          </div>
          <h2 className=' text-center myMaxWidth1200 mb-4'>{
            isIta ?
              <div>La tecnologia è complessa e i prodotti sono unici?<br/> Quando le parole non bastano, ecco che entrano in gioco le animazioni. Con effetti speciali e design creativi, le animazioni 3D offrono un <b>modo dinamico e visivamente accattivante per trasmettere informazioni complesse</b>, rendendo più facile per il pubblico comprendere il prodotto ed il suo potenziale.</div>
              : <div>Technology is complex and products are unique?<br/> Sometimes, words are not enough: that’s when animations step in. With special effects and creative designs, 3D animations offer a <b>dynamic and visually compelling way to convey multifaceted information</b>, making it easier for audiences to understand the product and engage with it.</div>
          }
          </h2>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 mb-5 text-secondary'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-column gap-5'>
            <h1 className='font-nycd mt-5 mb-5 text-center fw-light text-info myMaxWidth1600'>{
              isIta ?
                <div>Ottimizza i tuoi sforzi di marketing comunicando in modo efficace l'unicità dei tuoi prodotti e lasciando un segno nella mente dei tuoi clienti ideali.</div>
                : <div>Enhance your marketing efforts by effectively communicating the uniqueness of your products and leave a lasting impression on your ideal customers.</div>
            }
            </h1>
            <img className='myMaxWidth200' src={graphic} alt="" />
            <div>
              <h1 className='myMaxWidth1200 text-center text-dark display-3'>{
                isIta ?
                  <div>Offriamo due diverse tipologie di animazioni:</div>
                  : <div>We offer two different types of animations:</div>
              }
              </h1>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center myMaxWidth1400 pt-4'>{
              isIta ?
                <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextFucsia' style={{ borderLeft: "4px solid #e82283" }}><b>Animazioni commerciali </b></span> </h1>
                : <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextFucsia' style={{ borderLeft: "4px solid #e82283" }}><b>Commercial Animations </b></span> </h1>
            }
              {
                isIta ?
                  <h2 className='mt-4 pb-5'> queste animazioni, più lunghe ed elaborate, sono ideali per spiegare prodotti odontoiatrici di media/alta complessità, mettendo in mostra le caratteristiche e le peculiarità. Sono pensate per essere caricate su piattaforme di condivisione video come YouTube e Vimeo, oltre che per essere diffuse tramite newsletter.</h2>
                  : <h2 className='mt-4 pb-5'> these animations – longer and more elaborated – are ideal for explaining dental products of medium/high complexity, focusing on their features and characteristics. They are designed to be uploaded on video-sharing platforms such as YouTube and Vimeo, as well as to be spread through newsletters.</h2>
              }
              {
                isIta ?
                  <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextAcqua' style={{ borderLeft: "4px solid #14a7ad" }}><b>Animazioni social </b></span> </h1>
                  : <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextAcqua' style={{ borderLeft: "4px solid #14a7ad" }}><b>Social Animations </b></span> </h1>
              }
              {
                isIta ?
                  <h2 className='mt-4 mb-3'> queste animazioni sono state progettate appositamente per le pagine dei social media grazie al loro formato verticale che le rende perfette per essere viste su smartphone e tablet. Brevi e d'impatto per catturare l'attenzione dello spettatore, sono lo strumento perfetto per far conoscere le caratteristiche più essenziali dei vostri prodotti e sono, quindi, ideali per prodotti di bassa/media complessità.</h2>
                  : <h2 className='mt-4 mb-3'> these animations are designed specifically for social media pages thanks to their vertical format that makes them perfect to be seen and consumed on smartphones and tablets. Short and impactful to grab the viewer’s attention, they are the perfect tool to get your products’ most essential features across and are therefore ideal for products of low/medium complexity.</h2>
              }
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

      </div>
    </MainLayout>
  )
}

export default AnimationsPage