import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import bgTrade from '../../assets/images/coExhibition/bgTradeShow.jpg';
import steps from '../../assets/images/eventsOrganizzation/motivation.png';
import workshop from '../../assets/images/eventsOrganizzation/workshop.png';
import conference from '../../assets/images/eventsOrganizzation/stage.png';
import expo from '../../assets/images/eventsOrganizzation/poster.png';
import { useSelector } from 'react-redux';

const EventsOrganizzationPage = () => {

  const isIta = useSelector((state) => state.createUser.languageIta);

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgTrade})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>{
              isIta
                ? <div>Organizzazione Eventi</div>
                : <div>Events Organization</div>
            }
            </h1>
            {
              isIta
                ? <div><h2 className='font-nycd text-info my-5'>ORGANIZZA IL PROSSIMO GRANDE EVENTO</h2></div>
                : <div><h2 className='font-nycd text-info my-5'>ORGANIZE THE NEXT BIG EVENT</h2></div>
            }

            <h3 className='fw-light'>{
              isIta
                ? <div>Il prossimo evento memorabile potrebbe essere il tuo.</div>
                : <div>The next memorable event can be your own.</div>
            }
            </h3>
          </div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth80' src={conference} alt="img" />
            <img className='myMaxWidth80' src={expo} alt="img" />
            <img className='myMaxWidth80' src={workshop} alt="img" />
          </div>
          <h2 className=' text-center myMaxWidth1200'>{
            isIta
              ? <div>Siamo specializzati nell'organizzazione di <b>conferenze</b>, <b>fiere</b> e <b>workshop</b> nel campo medicale e dentale. La tua visione è tutto: grazie al nostro team qualificato tutti gli eventi sono altamente personalizzabili e personalizzati, appositamente progettati per adattarsi sia alle tue idee che alle esigenze del tuo pubblico.</div>
              : <div>We are specialized in the organization of <b>conferences</b>, <b>trade fairs</b> and <b>workshops</b> in the medical and dental field of all scopes and dimensions. Your vision is everything: thanks to our skilled team all events are highly customizable and personalized, specially designed to fit both your ideas and the needs of your audience.</div>
          }
          </h2>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          {
            isIta
              ? <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Organizzare e realizzare grandi eventi non è più un processo opprimente. </h1></div>
              : <div><h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Organizing and delivering great events is no longer an overwhelming process. </h1></div>
          }

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={steps} alt="" />
            <div>
              <h2 className='myMaxWidth700'>{
                isIta
                  ? <div>Ogni singolo passaggio ed ogni singolo dettaglio sarà curato in base alle vostre necessità. Partendo dal concept iniziale, fino alle sue implementazioni più pratiche, includendo logistica, marketing e comunicazioni strategiche, ogni momento sarà <b>personalizzato</b> per garantire il successo dell'evento.</div>
                  : <div>Every single step and detail will be taken care of according to your necessities. Starting from the initial concept to its most practical implementations, including logistics as well as marketing and strategic communications, each moment will be <b>crafted and tailored</b> to guarantee the success of the event.</div>
              }
              </h2>
            </div>
          </div>
          <div className='px-5 text-secondary d-flex flex-column align-items-center'>
            {
              isIta ?
                <div><i className='my-5 bi bi-info-circle'> Disponibile solo in Italia.</i></div>
                : <div><i className='my-5 bi bi-info-circle'> Only available in Italy.</i></div>
            }
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

      </div>
    </MainLayout>
  )
}

export default EventsOrganizzationPage