import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _AboutUs from '../components/_AboutUs';

const AboutUsPage = () => {
  return (
    <>
    <MainLayout>
        <_AboutUs/>
    </MainLayout>
    </>
  )
}

export default AboutUsPage