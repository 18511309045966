import React from 'react';
import NavigationBar from '../components/NavigationBar';
import bg2 from '../assets/bgLogo2.jpg';

const MainLayout = ({ children }) => {
    return (
        <>
            <div style={{ backgroundImage: `url(${bg2})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100vh", width: "100%" }}>
                <NavigationBar />
                <div style={{ backgroundColor: "transparent" }}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default MainLayout